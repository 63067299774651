import gql from 'graphql-tag'

export default (lang: string) => {
    return gql`query {
        data:queryHomepageContents {
            data {
                content {
                    ${lang} {
                        ... on PagesheroComponent {
                            title
                            subline
                            headline
                            background {
                                url
                                pixelWidth:metadata(path: "pixelWidth")
                                pixelHeight:metadata(path: "pixelHeight")
                                imageQuality:metadata(path: "imageQuality")
                                alt:metadata(path: "alt")
                                focusX:metadata(path: "focusX")
                                focusY:metadata(path: "focusY")
                            }
                        }
                        ... on PageslistgridcombiComponent {
                            title
                            description
                            list {
                                ... on PagesgridlistelementiconComponent {
                                    description
                                    icon {
                                        data {
                                            name {
                                                iv
                                            }
                                            color {
                                                iv
                                            }
                                        }
                                    }
                                }
                                ... on PagesgridlistelementimageComponent {
                                    description
                                    image {
                                        url
                                        pixelWidth: metadata(path: "pixelWidth")
                                        pixelHeight: metadata(path: "pixelHeight")
                                        imageQuality: metadata(path: "imageQuality")
                                        alt: metadata(path: "alt")
                                        focusX: metadata(path: "focusX")
                                        focusY: metadata(path: "focusY")
                                    }
                                }
                            }
                            grid {
                                ... on PagesgridelementdownloadComponent {
                                    content
                                    file {
                                        url
                                    }
                                }
                                ... on PagesgridelementlinkComponent {
                                    content
                                    link
                                }
                                ... on PagesgridelementtextComponent {
                                    content
                                }
                                ... on PagesgridelementtexticonComponent {
                                    content
                                    icon {
                                        url
                                        pixelWidth: metadata(path: "pixelWidth")
                                        pixelHeight: metadata(path: "pixelHeight")
                                        imageQuality: metadata(path: "imageQuality")
                                        alt: metadata(path: "alt")
                                        focusX: metadata(path: "focusX")
                                        focusY: metadata(path: "focusY")
                                    }
                                }
                                ... on PagesgridelementtextimageComponent {
                                    content
                                    image {
                                        url
                                        pixelWidth: metadata(path: "pixelWidth")
                                        pixelHeight: metadata(path: "pixelHeight")
                                        imageQuality: metadata(path: "imageQuality")
                                        alt: metadata(path: "alt")
                                        focusX: metadata(path: "focusX")
                                        focusY: metadata(path: "focusY")
                                    }
                                }
                                ... on PagesgridelementtextvideoComponent {
                                    content
                                    videos {
                                        url
                                        mimeType
                                    }
                                }
                            }
                            cta {
                                data {
                                    label {
                                        ${lang}
                                    }
                                    link {
                                        ${lang}
                                    }
                                    description {
                                        ${lang}
                                    }
                                    newPage {
                                        ${lang}
                                    }
                                }
                            }
                        }
                        ... on Pageshero2Component {
                            title
                            subline
                            headline
                            background {
                                url
                            }
                            backgroundImage {
                                url
                                pixelWidth:metadata(path: "pixelWidth")
                                pixelHeight:metadata(path: "pixelHeight")
                                imageQuality:metadata(path: "imageQuality")
                                alt:metadata(path: "alt")
                                focusX:metadata(path: "focusX")
                                focusY:metadata(path: "focusY")
                            }
                        }
                        ... on PagestextComponent {
                            title
                            content
                            cta {
                                data {
                                    label {
                                        ${lang}
                                    }
                                    link {
                                        ${lang}
                                    }
                                    description {
                                        ${lang}
                                    }
                                    newPage {
                                        ${lang}
                                    }
                                }
                            }
                        }
                        ... on PageshistogramhComponent {
                            title
                            content {
                                data {
                                    description {
                                        ${lang}
                                    }
                                    icon {
                                        ${lang} {
                                            url
                                            pixelWidth:metadata(path: "pixelWidth")
                                            pixelHeight:metadata(path: "pixelHeight")
                                            imageQuality:metadata(path: "imageQuality")
                                            alt:metadata(path: "alt")
                                            focusX:metadata(path: "focusX")
                                            focusY:metadata(path: "focusY")
                                        }
                                    }
                                }
                            }
                            cta {
                                data {
                                    label {
                                        ${lang}
                                    }
                                    link {
                                        ${lang}
                                    }
                                    description {
                                        ${lang}
                                    }
                                    newPage {
                                        ${lang}
                                    }
                                }
                            }
                        }
                        ... on PagesgridComponent {
                            title
                            content {
                                ... on PagesgridelementdownloadComponent {
                                    content
                                    file {
                                        url
                                    }
                                }
                                ... on PagesgridelementlinkComponent {
                                    content
                                    link
                                }
                                ... on PagesgridelementtextComponent {
                                    content
                                }
                                ... on PagesgridelementtexticonComponent {
                                    content
                                    icon {
                                        url
                                        pixelWidth:metadata(path: "pixelWidth")
                                        pixelHeight:metadata(path: "pixelHeight")
                                        imageQuality:metadata(path: "imageQuality")
                                        alt:metadata(path: "alt")
                                        focusX:metadata(path: "focusX")
                                        focusY:metadata(path: "focusY")
                                    }
                                }
                                ... on PagesgridelementtextimageComponent {
                                    content
                                    image {
                                        url
                                        pixelWidth:metadata(path: "pixelWidth")
                                        pixelHeight:metadata(path: "pixelHeight")
                                        imageQuality:metadata(path: "imageQuality")
                                        alt:metadata(path: "alt")
                                        focusX:metadata(path: "focusX")
                                        focusY:metadata(path: "focusY")
                                    }
                                }
                                ... on PagesgridelementtextvideoComponent {
                                    content
                                    videos {
                                        url
                                        mimeType
                                    }
                                }
                            }
                            cta {
                                data {
                                    label {
                                        ${lang}
                                    }
                                    link {
                                        ${lang}
                                    }
                                    description {
                                        ${lang}
                                    }
                                    newPage {
                                        ${lang}
                                    }
                                }
                            }
                        }
                        ... on PagestimelineComponent {
                            title
                            content {
                                ... on PagestimelineelementtextComponent {
                                    title
                                    content
                                }
                            }
                            cta {
                                data {
                                    label {
                                        ${lang}
                                    }
                                    link {
                                        ${lang}
                                    }
                                    description {
                                        ${lang}
                                    }
                                    newPage {
                                        ${lang}
                                    }
                                }
                            }
                        }
                        ... on PagesvideoComponent {
                            title
                            video {
                                url
                            }
                            cta {
                                data {
                                    label {
                                        ${lang}
                                    }
                                    link {
                                        ${lang}
                                    }
                                    description {
                                        ${lang}
                                    }
                                    newPage {
                                        ${lang}
                                    }
                                }
                            }
                        }
                        ... on PagesimageComponent {
                            title
                            imageDark {
                                url
                                pixelWidth:metadata(path: "pixelWidth")
                                pixelHeight:metadata(path: "pixelHeight")
                                imageQuality:metadata(path: "imageQuality")
                                alt:metadata(path: "alt")
                                focusX:metadata(path: "focusX")
                                focusY:metadata(path: "focusY")
                            }
                            imageLight {
                                url
                                pixelWidth:metadata(path: "pixelWidth")
                                pixelHeight:metadata(path: "pixelHeight")
                                imageQuality:metadata(path: "imageQuality")
                                alt:metadata(path: "alt")
                                focusX:metadata(path: "focusX")
                                focusY:metadata(path: "focusY")
                            }
                            fullWidth
                            cta {
                                data {
                                    label {
                                        ${lang}
                                    }
                                    link {
                                        ${lang}
                                    }
                                    description {
                                        ${lang}
                                    }
                                    newPage {
                                        ${lang}
                                    }
                                }
                            }
                        }
                        ... on PagesgridlistComponent {
                            title
                            content {
                                ... on PagesgridlistelementiconComponent {
                                    description
                                    icon {
                                        data {
                                            name {
                                                iv
                                            }
                                            color {
                                                iv
                                            }
                                        }
                                    }
                                }
                                ... on PagesgridlistelementimageComponent {
                                    description
                                    image {
                                        url
                                        pixelWidth: metadata(path: "pixelWidth")
                                        pixelHeight: metadata(path: "pixelHeight")
                                        imageQuality: metadata(path: "imageQuality")
                                        alt: metadata(path: "alt")
                                        focusX: metadata(path: "focusX")
                                        focusY: metadata(path: "focusY")
                                    }
                                }
                            }
                            cta {
                                data {
                                    label {
                                        ${lang}
                                    }
                                    link {
                                        ${lang}
                                    }
                                    description {
                                        ${lang}
                                    }
                                    newPage {
                                        ${lang}
                                    }
                                }
                            }
                        }
                        ... on PagestextimageComponent {
                            title
                            image {
                                url
                                pixelWidth:metadata(path: "pixelWidth")
                                pixelHeight:metadata(path: "pixelHeight")
                                imageQuality:metadata(path: "imageQuality")
                                alt:metadata(path: "alt")
                                focusX:metadata(path: "focusX")
                                focusY:metadata(path: "focusY")
                            }
                            content
                            imageLeft
                            cta {
                                data {
                                    label {
                                        ${lang}
                                    }
                                    link {
                                        ${lang}
                                    }
                                    description {
                                        ${lang}
                                    }
                                    newPage {
                                        ${lang}
                                    }
                                }
                            }
                        }
                        ... on PagespriceComponent {
                            title
                            hint
                            priceSubText
                            price
                            cta {
                                data {
                                    label {
                                        de
                                    }
                                    link {
                                        de
                                    }
                                    description {
                                        de
                                    }
                                    newPage {
                                        de
                                    }
                                }
                            }
                        }
                        ... on PageslinksComponent {
                            title
                            pages {
                                data {
                                    refContent {
                                        ${lang} {
                                            label
                                            labelShort
                                            description
                                            descriptionShort
                                            image {
                                                url
                                                pixelWidth:metadata(path: "pixelWidth")
                                                pixelHeight:metadata(path: "pixelHeight")
                                                imageQuality:metadata(path: "imageQuality")
                                                alt:metadata(path: "alt")
                                                focusX:metadata(path: "focusX")
                                                focusY:metadata(path: "focusY")
                                            }
                                        }
                                    }
                                    routing {
                                        ${lang} {
                                            routeName
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }`
}
