
import { apolloProvider } from '@/boot/apollo'
import { get_short_locale } from '@/boot/i18n'
import { API_Query_Data, API_Query_Language_Data_Helper } from '@/types/API'
import { CMS_COMPONENT_Pageshero, CMS_SCHEMA_Pages } from '@/types/cms/pages'
import homepage_query from '@/schemas/homepage'

import { defineComponent } from 'vue'

export default defineComponent({
    name: 'p_pages_index',

    data () {
        return {
            content      : [new CMS_COMPONENT_Pageshero()],
            lang         : get_short_locale(this.$route.meta.lang ? this.$route.meta.lang : ''),
            page_id      : this.$route.meta.id || '',
            init_request : 0
        }
    },

    watch: {
        '$route.meta.lang': {
            handler () {
                if (this.init_request > 1) {
                    this.lang = get_short_locale(this.$route.meta.lang ? this.$route.meta.lang : '')
                    this.get_data()
                } else {
                    this.init_request++
                }
            },
            immediate: true
        },

        '$route.name': {
            handler () {
                if (this.init_request > 1) {
                    this.page_id = this.$route.meta.id || ''
                    this.get_data()
                } else {
                    this.init_request++
                }
            },
            immediate: true
        }
    },

    methods: {
        get_data () {
            this.content = []

            apolloProvider.defaultClient.query({
                query     : homepage_query(this.lang),
                variables : {
                    id: this.page_id.toString()
                },
                fetchPolicy: 'cache-first'
            }).then((x: API_Query_Data<API_Query_Language_Data_Helper<CMS_SCHEMA_Pages>>) => {
                this.content = Object.values(x.data.data[0].data.content)[1]
            }).catch((e) => {
                console.error(e)
            })
        }
    },

    created () {
        this.get_data()
    }
})
