import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row items-center justify-evenly text-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content || [], (component) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$component_register(component.__typename)), {
        key: component,
        data: component,
        id: _ctx.$utf8.encode(component.title !== null ? component.title : ''),
        ref_for: true,
        ref: _ctx.$utf8.encode(component.title !== null ? component.title : ''),
        type: "products",
        source_data: _ctx.content
      }, null, 8, ["data", "id", "source_data"]))
    }), 128))
  ]))
}